import React from "react"
import { MDBContainer, MDBRow, MDBCol, MDBCarousel, MDBCarouselInner, MDBCarouselItem } from "mdbreact"
import { Link } from "gatsby"
import Layout from "../components/TLCLayout"
import wash1 from "../images/services/wash/782811_orig.jpg"
import wash2 from "../images/services/wash/3996043_orig.jpg"
import wash3 from "../images/services/wash/4671555_orig.jpg"
import wash4 from "../images/services/wash/6676783_orig.jpg"
import wash5 from "../images/services/wash/6968660_orig.jpg"
import wash6 from "../images/services/wash/9286595_orig.jpg"

var listServicesListStyle = {
    paddingLeft: '50px',
}

const DetailingServicesPage = () => (
    <Layout heroTitle="Services" heroImageUrl="./images/hero-dark.jpg">
        <MDBContainer style={{paddingTop: '100px', paddingBottom: '100px'}}>
            <MDBRow>
                <MDBCol>
                    <h3>Signature Protection Detail</h3>
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol md="8">
                    <p>
                        Sometimes all your vehicle needs to look its best is a thorough decontamination and sealant. Our Signature Protection Detail is designed for a vehicle that has been well maintained, but might need some help getting fully sorted. If your vehicle has swirls, or other paint defects, please look at our <Link to="/paintrefinement">Paint Correction services</Link>
                    </p> 

                    <ul style={listServicesListStyle}>
                        <li>
                            Foam pre-soak bath to break up heavy dirt and debris on surface
                        </li>
                        <li>
                            Gentle two bucket hand wash with pH neutral shampoo
                        </li>
                        <li>
                            Clay bar decontamination of paint and glass to remove bonded contaminants
                        </li>
                        <li>
                            Chemical decontamination with iron remover
                        </li>
                        <li>
                            Engine bay steam cleaned
                        </li>
                        <li>
                            Vehicle blown dry to prevent adding swirls in the paint
                        </li>
                        <li>
                            Sealant applied to the paint, wheels, and glass providing 4-6 months of protection
                        </li>
                        <li>
                            Door jams cleaned and sealed
                        </li>
                        <li>
                            Exhaust tips polished and sealed
                        </li>
                        <li>
                            Exterior trim cleaned and dressed
                        </li>
                        <li>
                            Mirrors and glass cleaned inside and out
                        </li>
                    </ul>                                       
                </MDBCol>
                <MDBCol md="4">
                    <MDBCarousel
                        activeItem={1}
                        length={6}
                        showControls={false}
                        showIndicators={false}
                        className="z-depth-1"
                        style={{padding: 0, marginTop: '25px'}}
                    >
                        <MDBCarouselInner>
                            <MDBCarouselItem itemId="1">
                                <img className="d-block w-100" src={wash1} />
                            </MDBCarouselItem>
                            <MDBCarouselItem itemId="2">
                                <img className="d-block w-100" src={wash2} />
                            </MDBCarouselItem>          
                            <MDBCarouselItem itemId="3">
                                <img className="d-block w-100" src={wash3} />
                            </MDBCarouselItem> 
                            <MDBCarouselItem itemId="4">
                                <img className="d-block w-100" src={wash4} />
                            </MDBCarouselItem> 
                            <MDBCarouselItem itemId="5">
                                <img className="d-block w-100" src={wash5} />
                            </MDBCarouselItem> 
                            <MDBCarouselItem itemId="6">
                                <img className="d-block w-100" src={wash6} />
                            </MDBCarouselItem>                                                                                                                                   
                        </MDBCarouselInner>
                    </MDBCarousel>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    

    </Layout>
)

export default DetailingServicesPage